.item {
  margin-top: 20px;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}

.status {
  margin-right: 10px;
}

.img {
  object-fit: cover;
  height: 500px;
  width: 100%;
}
