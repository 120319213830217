.wrapper {
  display: flex;
}

.main {
  width: calc(100%);
  padding-left: 200px;
}

.content {
  padding: 15px;
}
