.block {
  padding: 30px 0;
}

.title {
  display: flex;
  align-items: center;
}

.title button {
  margin-left: 7px;
}
