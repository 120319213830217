a {
  color: inherit;
}

.scale {
  transition: transform ease 0.2s;
}

.scale:hover {
  transform: scale(1.1);
}

/* .body {
  min-height: calc(100vh - 175px);
} */

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@import "~normalize.css";
@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
