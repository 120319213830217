.login {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: inherit;
  height: 100vh;
}

.login_form {
  width: 35%;
  border: 1px solid var(--decor-grey);
  margin-bottom: 50px;
  padding: 30px !important;
}

.login_logo {
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
}

.login_logo img {
  width: 80px;
  height: auto;
}

.login__input {
  margin-bottom: 30px;
}

.login_btn_row {
  margin-top: 35px;
  display: flex;
  justify-content: flex-end;
}

.login_btn_row button {
  width: 100px;
}
