.block {
  margin-top: 40px;
}

.grid {
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 40px;
}

.card {
  padding: 0 !important;
}

.content {
  height: 300px;
}

.content img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
