.card {
  justify-content: space-between;
}

.flex {
  display: flex;
  align-items: center;
}

.status {
  margin-right: 10px;
}

.dragItem {
  color: rgb(28, 33, 39);
  margin-right: 15px;
}

.title {
  margin-right: 5px;
}
