.item_wrapper {
  padding-bottom: 50px;
  border-bottom: 1px solid #e0e1e4;
}

.adminRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.adminInfo {
  margin-bottom: 10px;
}

.adminInfoStatus {
  margin-right: 20px;
}

.item {
  transition: all 0.5s ease;
  cursor: pointer;
}

.item_img {
  margin-bottom: 35px;
}

.item_img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  max-height: 310px;
}

.item_subTitle {
  display: flex;
  align-items: center;
  font-size: 0.45rem;
  line-height: 18px;
  text-transform: uppercase;
  color: var(--text-light);
}

.item_decor {
  width: 1px;
  height: 18px;
  margin-bottom: 30px;
}

.item_title {
  font-weight: 500;
  font-size: 1.15rem;
  font-style: normal;
  line-height: 140%;
  margin-bottom: 25px;
}

.item_descr {
  font-size: 0.6rem;
  color: var(--text-light);
}

/* .item:hover {
  transform: translateY(-10px);
} */

@media (max-width: 990px) {
  .item_img {
    margin-bottom: 25px;
  }

  .item_subTitle {
    font-size: 0.4rem;
  }

  .item_title {
    font-size: 1rem;
    margin-bottom: 15px;
  }

  .item_descr {
    font-size: 0.5rem;
  }
}

@media (max-width: 460px) {
  .item_img {
    margin-bottom: 10px;
  }

  .item_subTitle {
    font-size: 0.38rem;
  }

  .item_title {
    font-size: 0.8rem;
    margin-bottom: 5px;
  }

  .item_wrapper {
    padding-bottom: 25px;
  }
  .item_descr {
    font-size: 0.48rem;
  }
}
