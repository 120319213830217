.collapse {
  cursor: pointer;
}

.chevron {
  margin-left: 10px;
  transition: 0.2s transform;
}

.chevron-active {
  transform: rotate(180deg);
}

.description {
  margin-top: 20px;
  margin-bottom: 20px;
}
