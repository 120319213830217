.rowContent {
  display: flex;
  align-items: center;
  justify-content: center;
}

.form {
  width: 40%;
}

.form_title {
  margin-bottom: 35px;
  display: flex;
  align-items: center;
}

.form_title button {
  margin-right: 15px;
}

.btnRow {
  display: flex;
  justify-content: flex-end;
  margin-top: 35px;
}

.btnRow button {
  width: 100px;
}
