.header {
  width: 100%;
  height: 80px;
  padding: 15px 50px 15px 15px;
  display: flex;
  justify-content: flex-end;
}

.header_icon {
    margin-right: 8px;
}