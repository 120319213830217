.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.container {
  padding: 0 15px;
}

.regions {
  margin: 32px 0;
}

.regions h2 {
  margin-bottom: 10px;
}
