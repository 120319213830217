.menu {
  width: 200px;
  padding: 20px;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}

.menu__logo {
  height: 80px;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.menu__logo img {
  height: 100%;
}
